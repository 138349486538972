import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'


export default () => (
	<StaticQuery 
		query = {
			graphql`
			query {
				IndustriesGraphic: file(relativePath: { eq: "imgIndustriesGraphic.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
					...GatsbyImageSharpFluid
					}
				}
				}
			}
			`
		}
	
		render={data => (
			<Img fluid={data.IndustriesGraphic.childImageSharp.fluid}
				sizes={{...data.IndustriesGraphic.childImageSharp.fluid, aspectRatio: 19/9}}
				overlayColor="#04040454"
				alt='Music Technology'
				style={{
					transition: 'opacity 0.5s',
					transitionDelay: '0.5s',
					width: '100%',
				}}
				imgStyle={{ objectFit: 'cover' }} 	// none, fill, contain, cover, scale-down 
			/>
		)}
	/>	
);	
	
/*
	<Img fluid={props.data.IndustriesGraphic.childImageSharp.fluid}
		alt='Music Technology'
		style={{
				position: 'absolute',
				top: '0',
				left: '0',
				transition: 'opacity 0.5s',
				transitionDelay: '0.5s',
				opacity: '1',
				width: '50%',
				height: '50%',
				maxHeight: 'calc(50vh - 4rem)',
				margin: '1rem',
				objectFit: 'cover',
				objectPosition: 'center'
		}}
		imgStyle={{ objectFit: 'contain' }}
	/> 
*/
