import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'


export default () => (
	<StaticQuery
		query={
			graphql`
			query {
				IndustriesHeader: file(relativePath: { eq: "imgIndustriesHeader.png" }) {
					childImageSharp {
						fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid
						}
					}
				}
			}
			`
		}

		render={data => (
			<Img fluid={data.IndustriesHeader.childImageSharp.fluid}
				overlayColor="#04040454"
				alt='Industries Header'
				style={{
					transition: 'opacity 0.5s',
					transitionDelay: '0.5s',
					height: '10vh',
				}}
				imgStyle={{ objectFit: 'contain' }} 	// none, fill, contain, cover, scale-down 
			/>
		)}
	/>
);

/*
	<Img fluid={props.data.IndustriesHeader.childImageSharp.fluid}
		alt='Intellect Logo'
		style={{
				position: 'absolute',
				top: '0',
				left: '0',
				transition: 'opacity 0.5s',
				transitionDelay: '0.5s',
				opacity: '1',
				width: '50%',
				height: '50%',
				maxHeight: 'calc(50vh - 4rem)',
				margin: '1rem',
				objectFit: 'cover',
				objectPosition: 'center'
		}}
		imgStyle={{ objectFit: 'contain' }}
	/>
*/
