import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import IndustriesHeader from '../../StaticQueries/IndustriesHeader'
import IndustriesGraphic from '../../StaticQueries/IndustriesGraphic'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)

const styles = theme => ({
  paper: {
    maxWidth: 1700,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  container: {
    padding: '48px 36px 0',
  },
})

function IndustriesContent({ classes }) {
  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>

          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= I°N°D°U°S°T°R°I°E°S ≡≡≡ S°U°P°P°O°R°T°E°D ≡≡≡ T°O°-D°A°T°E =-</h1> */}
			<IndustriesHeader/>
          </AppBar>

          <div className={classes.contentWrapper} style={{fontSize: '120%', color: 'blue'}}>
            {/* <Typography color="textPrimary" align="justify"> */}
			<typography color="textPrimary" align="justify">
					<IndustriesGraphic/>
					<br/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Typesetting</h2>
					<br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Helena Esperanto-Asocio, software development [Volunteer Work])
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Pulp &amp; Paper</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Prochem Mixing Equipment/Robbins &amp; Myers Canada, software development, systems &amp; database administration)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Civil Engineering</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(J&amp;P Overseas Ltd., software development, administration, management)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Innovation Institutes</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Technology Transfer Institute Of Greece, systems work)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Computer Systems User Training &amp; Support</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(J&amp;P Overseas Ltd. and Prochem Mixing Equipment/Robbins &amp; Myers Canada)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Beverages Industry</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Cott Beverages Desktop management, Laptop Evaluation, Antivirus Strategy, Systems Administration and other projects)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Financial Industry</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Speedware Corporation Financials software development)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Banking Industry</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Toronto Dominion Bank [Risk Projects] software development)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Investment Technology Industry</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Toronto Dominion Securities Inc. &amp; ITG [Investment Technology Group] software engineering)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Technical Writing</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(UBS BANK, CANADA)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Academic Instruction</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Dalhousie University M.Sc. teaching assistantships &amp; Seneca College teaching)
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Architecture &amp; Surveying</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Technology support in Athens, Greece [Volunteer Work])
					<br/>
					<hr/>
					<br/>
					<h2 style={{fontSize: '120%', color: '#A0522D'}}>&#x27A5; Oceanography</h2>
					<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Bedford Institute Of Oceanography &amp; Dalhousie University [M.Sc. research])
			</typography>
			{/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

IndustriesContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(IndustriesContent)
