import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import IndustriesContent from 'components/industriesContent'

function IndusctriesPage({ location }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : ''

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<IndustriesContent />
		</Layout>
	)
}

IndusctriesPage.propTypes = {
	location: PropTypes.object,
}

export default IndusctriesPage
